html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

#root {
  display: grid;
  grid-template-columns: auto minmax(auto, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
  border: none;
}

textarea {
  resize: none !important;
}

.message-loader-dot {
  width: 10px;
  height: 10px;
  background: #0090e3;
  border-radius: 50%;
  opacity: 0.5;
  animation: grow 1s 2s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.6s;
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.contentEditable {
  width: 100%;
  padding: 0px;
  position: relative;
  z-index: 1;
  margin: 0px;

  &&:focus {
    outline: none;
  }
}

.placeholder {
  position: absolute;
  color: #ddd;
  top: 0;
  left: 0;
  z-index: 0;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

a:hover {
  cursor: pointer;
}
